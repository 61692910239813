import * as ai from 'react-icons/ai';
import * as bs from 'react-icons/bs';
import * as di from 'react-icons/di';
import * as fi from 'react-icons/fi';
import * as fc from 'react-icons/fc';
import * as fa from 'react-icons/fa';
import * as gi from 'react-icons/gi';
import * as go from 'react-icons/go';
import * as gr from 'react-icons/gr';
import * as io from 'react-icons/io';
import * as md from 'react-icons/md';
import * as ri from 'react-icons/ri';
import * as ti from 'react-icons/ti';
import * as wi from 'react-icons/wi';
let icons = {...ai, ...fi, ...fc, ...gi, ...go, ...io, ...ti, ...wi, ...md, ...bs, ...di, ...fa, ...gr, ...ri}
export default function getIcon(name){
    return icons[name];
}